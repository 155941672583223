.CaseStudyTitle{
    font-size: 52px;
}

.CaseStudyDescription{
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 400;
    color: hsl(0, 0%, 50%);
    text-align: left;
    line-height: 170%;
}

.CS3-main-container{
    margin-top: 68px;
    display: grid;
    row-gap: 20px;
    column-gap: 25px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;
    background-color: rgb(255, 255, 255);
}


.HeaderCS3{
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 3;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 5%;
    margin-top: 10px;
}


.CS3-Role-BG-color{
    grid-column-start: 1;
    grid-column-end: 8;
    grid-row-start: 3;
    grid-row-end: 6;
    background-color: rgb(231, 238, 255);
     /* height: 250px; */
}

.CS3-Role-left-section{
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 30px;
}

.CS3-Role-right-section{
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 4;
    grid-row-end: 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 30px;
}


.CS3-text-colour{
    color: rgb(0, 24, 162);
}

.margin-cs2{
    margin-bottom: 100px;
} 

.CS3-Overview{
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 6;
    grid-row-end: 8;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 25px;
    margin-top: 40px;

}

.CS3-Overview-Tag{
    font-size: 20px;
    font-weight: 600;
    color: rgb(0, 110, 255);
    align-items: flex-start;
    justify-content: flex-start;
    
}

.CS3-Overview-Title{
    font-size: 28px;
    font-weight: 400;
    text-align: left;
}

.CS3-Overview-Desc{
    font-size: 16px;
    line-height: 1.6;
    color: rgb(81, 81, 81);
    font-weight: 400;
    text-align: left;
    margin-bottom: 50px;
}

.CS3-Audience{
    grid-column-start: 1;
    grid-column-end: 8;
    grid-row-start: 8;
    grid-row-end: 11;
    background-color: rgb(231, 238, 255);
     /* height: 250px; */
}

.CS3-Audience-left-section{
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 10;
    grid-row-end: 11;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 30px;
}

.CS3-Audience-right-section{
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 10;
    grid-row-end: 11;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 30px;
}

.CS3-Audience-Tag{
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 9;
    grid-row-end: 10;
    text-align: left;
    margin-top: 50px;
}
