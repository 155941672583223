.container_aboutpage{
  margin-top: 100px;
  height: inherit;
  display: grid;
  row-gap: 5px;
  column-gap: 5px;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr;
} 


.ProfilePic {
  /* background-color: red; */
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 3;
  grid-row-end: 4;
  width: 30%;
  justify-self: center;
}

.info1 {
  /* background-color: red; */
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 4;
  grid-row-end: 5;
  /* width: 500PX; */
  justify-self: start;
}


.info2 {
  /* background-color: red; */
  grid-column-start: 8;
  grid-column-end: 14;
  grid-row-start: 5;
  grid-row-end: 6;
  /* width: 500PX; */
}

.info3 {
  /* background-color: red; */
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 7;
  grid-row-end: 8;
  /* width: 500PX; */
}

.profilePic1{
  width: 100%;
}

.infoHeader{
  text-align: left;
  font-weight: 400;
}


.infoText{
  text-align: left;
  line-height: 1.5;
  font-weight: 300;
}


.imageText{
font-size: 14px;
}


.Title {
  /* background-color: red; */
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 10;
  grid-row-end: 11;
  text-align: left;
  /* font-size: 30px;
  font-weight:800; */
  /* width: 500PX; */
}

.imagesGrid1{
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 11;
  grid-row-end: 12;
  margin-bottom: 80px;
  
}

.imagesGrid2{
  grid-column-start: 8;
  grid-column-end: 14;
  grid-row-start: 11;
  grid-row-end: 12;
  
}

.gridImages{
  width: 100%;
  height: auto;
  margin-bottom: 2px;
}

/* mobile  */
@media (max-width: 480px) {
  .container_aboutpage{
    margin-top: 100px;
    height: inherit;
    display: grid;
    row-gap: 5px;
    column-gap: 5px;
    grid-template-columns: 20px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 20px;
  } 

  .ProfilePic {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 3;
    grid-row-end: 4;
    width: 70%;
    justify-self: center;
  }
  
  .info1 {
    /* background-color: red; */
    grid-column-start: 2;
    grid-column-end: 12;
    grid-row-start: 4;
    grid-row-end: 5;
    /* width: 500PX; */
    justify-self: start;
    margin-top: 25px;
    margin-bottom: 20px;
  }
  
  
  .info2 {
    /* background-color: red; */
    grid-column-start: 4;
    grid-column-end: 14;
    grid-row-start: 5;
    grid-row-end: 6;
    /* width: 500PX; */

    margin-bottom: 20px;
  }
  
  .info3 {
    /* background-color: red; */
    grid-column-start: 2;
    grid-column-end: 12;
    grid-row-start: 7;
    grid-row-end: 8;
    /* width: 500PX; */
  }

  .infoHeader{
    text-align: left;
    font-weight: 400;
    font-size: 22px;
  }
  
  
  .infoText{
    text-align: left;
    line-height: 1.4;
    font-weight: 300;
    text-align: 18px;
  }
  
  .Title {
    /* background-color: red; */
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 10;
    grid-row-end: 11;
    text-align: left;
    font-size: 22px;
    font-weight: 500;
    /* font-size: 30px;
    font-weight:800; */
    /* width: 500PX; */
  }

  .imagesGrid1{
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 11;
    grid-row-end: 12;
    margin-bottom: 80px;
    
  }
  
  .imagesGrid2{
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 12;
    grid-row-end: 13;
  }
  
  .gridImages{
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
}