
.fiverr-review-container{
  margin-top: 100px;
  height: inherit;
  display: grid;
  row-gap: 20px;
  column-gap: 5px;
  grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr;
  background-color: rgb(255, 255, 255);
} 

.ImageForTitle{
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 30px;
  margin-bottom: 30px;
  /* background-color: red; */
}

.Image-Memoji{
  width: 100%;
}

.fiver-review-title{
  grid-column-start: 5;
  grid-column-end: 11;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 60px;
  margin-bottom: 30px;
  /* background-color: red; */
}


.Words-title{
  margin-top: 60px;
  font-size: 50px;
  font-weight: 400;
}


.Words-desc{
  font-size: 15px;
  line-height: 1.4;
  font-weight: 300;
}


.link22{
  text-decoration: underline;
}
.reviewImage{
  width: 96%;
  height: auto;
  margin-bottom: 25px;
  border-radius: 10px; /* Adjust the radius as needed */
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
}

.reviewImageGrid1{
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 2;
  grid-row-end: 3;
}

.reviewImageGrid2{
  grid-column-start: 8;
  grid-column-end: 14;
  grid-row-start: 2;
  grid-row-end: 3;
}

/* Mobile Devices (max-width: 480px) */
@media (max-width: 480px) {
.fiverr-review-container{
  margin-top: 60px;
  height: inherit;
  display: grid;
  row-gap: 0px;
  column-gap: 0px;
  grid-template-columns: 20px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 20px;
  background-color: rgb(255, 255, 255);
} 

.ImageForTitle{
  grid-column-start: 2;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 30px;
  margin-bottom: 30px;
  /* background-color: red; */
}

.fiver-review-title{
  grid-column-start: 7;
  grid-column-end: 14;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 90px;
  margin-bottom: 0px;
  align-items: left;
  text-align: left;
  /* background-color: red; */
}


.Words-title{
  margin-top: 0px;
  font-size: 24px;
  font-weight: 400;
}

.Words-desc{
  font-size: 14px;
  line-height: 1.4;
  font-weight: 300;
}

.link22{
  text-decoration: none;
}

.reviewImageGrid1{
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 2;
  grid-row-end: 3;
}

.reviewImageGrid2{
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 3;
  grid-row-end: 4;
}
.reviewImage{
  width: 100%;
  height: auto;
  margin-bottom: 25px;
  border-radius: 5px; /* Adjust the radius as needed */
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
}

}