.page3_container {
    margin-top: 50px;
    display: grid;
    row-gap: 30px;
    column-gap: 5px;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr;
    background-color: rgb(255, 255, 255);
  }
  

  /* section 1: back arrow */
.page3_backicon {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
  display: flex;
  align-items: left;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 30px;
}

.page3_backicon .back-arrow {
  display: inline-block;
  padding: 8px 12px; /* Add padding to make it more button-like */
  border-radius: 4px; /* Rounded corners for better aesthetics */
  cursor: pointer; /* Change the cursor to indicate a clickable button */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effect */
}

.page3_backicon .back-arrow:hover {
  background-color: #3452e628; /* Highlight background color */
  color: #000; /* Change the arrow color for better contrast */
}

  /* Section 2: Title EASyWork and Subtitle */
  .page3_Header {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 4;
    display: flex;
    align-items: left;
    font-size: 34px;
    font-weight: 600;
    text-align: left;
    line-height: 90%;
  }
  
  .page3_Description {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 5;
    grid-column-end: 14;
    grid-row-start: 3;
    grid-row-end: 4;
    display: flex;
    align-items: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
  }
  
  /* {section 3 : header image includes 3 wireframes } */
  .page3_HeaderImage {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 4;
    grid-row-end: 5;
    display: flex; /* Optional, for alignment purposes */
    justify-content: center; /* Optional, center-align the image */
    align-items: center; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
  }
  
  .page3_HeaderImage__img {
    max-width: 100%; /* Prevent the image from exceeding the container's width */
    height: auto; /* Maintain the aspect ratio */
  }


/* 
      {/* Section 4: My role and Responsibility with time duration and tools  */
  .page3_info_bg {
    grid-column-start: 1;
    grid-column-end: 15;
    grid-row-start: 5;
    grid-row-end: 6;
    background: #eff1ff;
    /* height: 400px; */
    /* padding-bottom: 430px; */
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .page3_info_Title {
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    line-height: 160%;
    margin-bottom: 10px;
  }
  
  .page3_info_Description {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    color: rgb(41, 41, 41);
  }
  
  .page3_section_left {
    grid-column-start: 3;
    grid-column-end: 9;
    grid-row-start: 5;
    grid-row-end: 6;
    margin-top: 80px;
    display: flex;
    justify-self: flex-start;
    flex-direction: column;
    text-align: left;
    margin-bottom: 50px;
  }
  
  .page3_section_right {
    grid-column-start: 10;
    grid-column-end: 14;
    grid-row-start: 5;
    grid-row-end: 6;
    margin-top: 80px;
    display: flex;
    justify-self: flex-start;
    flex-direction: column;
    text-align: left;
    margin-bottom: 50px;
  }
  
  .page3_TitleAndDescriptionGroup {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 40px;
  }
  
  /* section 5 : Design Challanes*/
  .page3_Header_Section5 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 6;
    grid-row-end: 7;
    display: flex;
    align-items: left;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    margin-top: 20px;
    margin-top: 50px;
    line-height: 150%;
    color: #3452E6;
  }
  
  .page3_Description_Section5 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 5;
    grid-column-end: 14;
    grid-row-start: 6;
    grid-row-end: 7;
    display: flex;
    align-items: left;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 10px;
    flex-direction: column;
    line-height: 150%;
    margin-top: 50px;
    margin-bottom: 40px;
  }
  
  .page3_BodyHeader {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    line-height: 150%;
    color: #000000;
    /* color: #3452E6; */
  }
  
  .page3_BodyText {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    line-height: 160%;
    color: #474747;
  }

  .page3_BodyText_Highlight {
    font-weight: 600;
    color: black;
  }


  .page3_BodyText_Highlight_Blue {
    font-weight: 700;
    color: #3452E6;
  }

  /* section 6 : Image for Userpainpoints  */
  .page3_Section8_HeaderImage {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 7;
    grid-row-end: 8;
    display: flex; /* Optional, for alignment purposes */
    justify-content: center; /* Optional, center-align the image */
    align-items: center; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
    /* margin-bottom: 40px; */
  }
  
  .page3_Section8_HeaderImage__img {
    max-width: 100%; /* Prevent the image from exceeding the container's width */
    height: auto; /* Maintain the aspect ratio */
  }

  /* section 7 : Officer query   */
  .page3_SectionOfficerQuery_background{
    grid-column-start: 1;
    grid-column-end: 15;
    grid-row-start: 8;
    grid-row-end: 12;
    background: #eff1ff;
  }

  .page3_SectionOfficerQuery_Image{
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 9;
    grid-row-end: 12;
   }

      /* section 8 : Design Process  */

      .page3_Section7_Text_Left{
        grid-column-start: 2;
        grid-column-end: 5;
        grid-row-start: 13;
        grid-row-end: 15;
        font-size: 24px;
        font-weight: 600;
        text-align: left;
        line-height: 150%;
        color: #3452E6;
        margin-top: 18px;
      }

      .page3_Section7_Text_Right{
        grid-column-start: 5;
        grid-column-end: 14;
        grid-row-start: 13;
        grid-row-end: 15;
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        line-height: 160%;
        color: #1e1e1e;
      }
    
    
      .page3_Section7_Header{
        font-size: 24px;
        font-weight: 600;
        text-align: left;
        line-height: 150%;
        color: #3452E6;
        margin-top: 20px;
      }
  
      .page3_Section7_HeaderImage {
        grid-column-start: 5;
        grid-column-end: 14;
        grid-row-start: 15;
        grid-row-end: 16;
        display: flex; /* Optional, for alignment purposes */
        justify-content: center; /* Optional, center-align the image */
        align-items: center; /* Optional, center-align vertically */
        overflow: hidden; /* Prevent content overflow */
        margin-bottom: 40px;
      }
      
      .page3_Section7_HeaderImage__img {
        max-width: 100%; /* Prevent the image from exceeding the container's width */
        height: auto; /* Maintain the aspect ratio */
      }
  
  
  /* section 9 : Key Insights  */
  .page3_Section6_background{
    grid-column-start: 1;
    grid-column-end: 15;
    grid-row-start: 16;
    grid-row-end: 19;
    background: #eff1ff;
  }

  .page3_Section6_Text{
    grid-column-start: 4;
    grid-column-end: 12;
    grid-row-start: 17;
    grid-row-end: 18;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    line-height: 160%;
    padding-bottom: 20px;
    padding-top: 15px;
    color: #000000;
    margin-bottom: 40px;
  }

  .page3_Section6_Header{
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: 160%;
    color: #3452E6;
    margin-top: 50px;
  }


    /* section 10 : Phase 1 implementation highlights  */
  .page3_Header_Section10 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 20;
    grid-row-end: 21;
    display: flex;
    align-items: left;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    margin-top: 40px;
    line-height: 150%;
    color: #3452E6;
  }
  
  .page3_Description_Section10 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 5;
    grid-column-end: 14;
    grid-row-start: 20;
    grid-row-end: 21;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    line-height: 150%;
    color: #1e1e1e;
    margin-top: 40px;
  }

  
    /* Section 11: dashboard image  */

    .page3_image1_Description{
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 150%;
    color: #3452E6;
    margin-top: 25px;
    }


    .page3_Section9_HeaderImage {
      grid-column-start: 2;
      grid-column-end: 14;
      grid-row-start: 22;
      grid-row-end: 23;
      display: flex; /* Optional, for alignment purposes */
      justify-content: center; /* Optional, center-align the image */
      align-items: center; /* Optional, center-align vertically */
      /* overflow: hidden; Prevent content overflow */
      margin-bottom: 10px;
      flex-direction: column;
    }
    
    .page3_Section9_HeaderImage__img {
      max-width: 100%; /* Prevent the image from exceeding the container's width */
      height: auto; /* Maintain the aspect ratio */
    }


        /* Section 12: dashboard image  */

    
        .page3_image2_Description{
          font-size: 16px;
          font-weight: 500;
          text-align: left;
          line-height: 150%;
          color: #3452E6;
          margin-top: 25px;
          margin-bottom: 30px;
          }
      
        .page3_Section12_HeaderImage {
          grid-column-start: 2;
          grid-column-end: 14;
          grid-row-start: 24;
          grid-row-end: 25;
          display: flex; /* Optional, for alignment purposes */
          justify-content: left; /* Optional, center-align the image */
          align-items: left; /* Optional, center-align vertically */
          overflow: hidden; /* Prevent content overflow */
          /* margin-bottom: 40px; */
          flex-direction: column;
        }
        
        .page3_Section12_HeaderImage__img {
          max-width: 100%; /* Prevent the image from exceeding the container's width */
          height: auto; /* Maintain the aspect ratio */
        }
  
    
        /* Section 13: dashboard image  */
        .page3_Section13_HeaderImage {
          grid-column-start: 2;
          grid-column-end: 14;
          grid-row-start: 26;
          grid-row-end: 27;
          display: flex; /* Optional, for alignment purposes */
          justify-content: center; /* Optional, center-align the image */
          align-items: center; /* Optional, center-align vertically */
          overflow: hidden; /* Prevent content overflow */
          /* margin-bottom: 40px; */
          flex-direction: column;
        }
        
        .page3_Section13_HeaderImage__img {
          max-width: 100%; /* Prevent the image from exceeding the container's width */
          height: auto; /* Maintain the aspect ratio */
        }
    

  /* Section 13: rack image  */
        .page3_Section14_HeaderImage {
          grid-column-start: 2;
          grid-column-end: 14;
          grid-row-start: 29;
          grid-row-end: 30;
          display: flex; /* Optional, for alignment purposes */
          justify-content: center; /* Optional, center-align the image */
          align-items: center; /* Optional, center-align vertically */
          overflow: hidden; /* Prevent content overflow */
          margin-bottom: 40px;
          flex-direction: column;
        }
        
        .page3_Section14_HeaderImage__img {
          max-width: 100%; /* Prevent the image from exceeding the container's width */
          height: auto; /* Maintain the aspect ratio */
        }

    /* Section 15: rack image  */
    .page3_Section15_HeaderImage {
      grid-column-start: 2;
      grid-column-end: 14;
      grid-row-start: 31;
      grid-row-end: 32;
      display: flex; /* Optional, for alignment purposes */
      justify-content: center; /* Optional, center-align the image */
      align-items: center; /* Optional, center-align vertically */
      overflow: hidden; /* Prevent content overflow */
      margin-bottom: 40px;
      flex-direction: column;
    }
    
    .page3_Section15_HeaderImage__img {
      max-width: 100%; /* Prevent the image from exceeding the container's width */
      height: auto; /* Maintain the aspect ratio */
    }

      /* Section 16: rack image  */
      .page3_Section16_HeaderImage {
        grid-column-start: 2;
        grid-column-end: 14;
        grid-row-start: 33;
        grid-row-end: 34;
        display: flex; /* Optional, for alignment purposes */
        justify-content: center; /* Optional, center-align the image */
        align-items: center; /* Optional, center-align vertically */
        overflow: hidden; /* Prevent content overflow */
        margin-bottom: 40px;
        flex-direction: column;
      }
      
      .page3_Section16_HeaderImage__img {
        max-width: 100%; /* Prevent the image from exceeding the container's width */
        height: auto; /* Maintain the aspect ratio */
      }  


/* section 17 : answer from officer image  */
.page3_SectionOfficerAnswer_background{
  grid-column-start: 1;
  grid-column-end: 15;
  grid-row-start: 35;
  grid-row-end: 39;
  background: #eff1ff;  
}

.page3_SectionOfficerAnswer_Image{
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 36;
  grid-row-end: 39;
 }

 /* section 18 : problems in phase one  */

 .page3_Header_Section18 {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 40;
  grid-row-end: 41;
  display: flex;
  align-items: left;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  margin-top: 40px;
  line-height: 150%;
  color: #3452E6;
}

.page3_Description_Section18 {
  grid-column-start: 5;
  grid-column-end: 14;
  grid-row-start: 40;
  grid-row-end: 41;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  line-height: 150%;
  color: #1e1e1e;
  margin-top: 40px;
}

/* Section 19: Problem 1  */
.page3_Section19_HeaderImage {
grid-column-start: 2;
grid-column-end: 14;
grid-row-start: 42;
grid-row-end: 44;
display: flex; /* Optional, for alignment purposes */
justify-content: center; /* Optional, center-align the image */
align-items: center; /* Optional, center-align vertically */
overflow: hidden; /* Prevent content overflow */
/* margin-bottom: 40px; */
flex-direction: column;
}

/* section 20 : problem 1 image  */
.page3_Section20_HeaderImage {
  grid-column-start: 4;
  grid-column-end: 12;
  grid-row-start: 44;
  grid-row-end: 45;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 40px;
}

.page3_Section20_HeaderImage__img {
  max-width: 100%; /* Prevent the image from exceeding the container's width */
  height: auto; /* Maintain the aspect ratio */
}

/* Section 21: Problem 2 text  */
.page3_Section21_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 46;
  grid-row-end: 47;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  /* margin-bottom: 40px; */
  flex-direction: column;
  }
  
  /* section 22 : problem 1 image  */
  .page3_Section22_HeaderImage {
    grid-column-start: 4;
    grid-column-end: 12;
    grid-row-start: 47;
    grid-row-end: 48;
    display: flex; /* Optional, for alignment purposes */
    justify-content: center; /* Optional, center-align the image */
    align-items: center; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
    margin-bottom: 40px;
  }
  
  .page3_Section22_HeaderImage__img {
    max-width: 100%; /* Prevent the image from exceeding the container's width */
    height: auto; /* Maintain the aspect ratio */
  }
  


/* Section 23: Problem 1 text  */
.page3_Section23_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 49;
  grid-row-end: 50;
  display: flex; /* Optional, for alignment purposes */
  justify-content: left; /* Optional, center-align the image */
  align-items: left; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  /* margin-bottom: 40px; */
  flex-direction: column;
  }
  
  /* section 24 : problem 1 image  */
  .page3_Section24_HeaderImage {
    grid-column-start: 4;
    grid-column-end: 12;
    grid-row-start: 50;
    grid-row-end: 51;
    display: flex; /* Optional, for alignment purposes */
    justify-content: center; /* Optional, center-align the image */
    align-items: center; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
    margin-bottom: 40px;
  }
  
  .page3_Section24_HeaderImage__img {
    max-width: 100%; /* Prevent the image from exceeding the container's width */
    height: auto; /* Maintain the aspect ratio */
  }
  
    
  /* section 25 : Key Insights  */
  .page3_Section25_background{
    grid-column-start: 1;
    grid-column-end: 15;
    grid-row-start: 52;
    grid-row-end: 55;
    background: #eff1ff;
  }

  .page3_Section25_Text{
    grid-column-start: 4;
    grid-column-end: 12;
    grid-row-start: 53;
    grid-row-end: 54;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    line-height: 160%;
    padding-bottom: 20px;

    padding-top: 15px;
    color: #000000;
    margin-bottom: 40px;
  }

  .page3_Section25_Header{
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: 160%;
    color: #3452E6;
    margin-top: 50px;
  }

  /* phase 2 start  */
 /* section 26 : phase 2 tag line   */

 .page3_Header_Section26 {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 55;
  grid-row-end: 56;
  display: flex;
  align-items: left;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  margin-top: 40px;
  line-height: 150%;
  color: #3452E6;
}

.page3_Description_Section26 {
  grid-column-start: 5;
  grid-column-end: 14;
  grid-row-start: 55;
  grid-row-end: 56;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  line-height: 150%;
  color: #1e1e1e;
  margin-top: 40px;
}

/* Section 27 : Problem 1  */
.page3_Section27_HeaderImage {
grid-column-start: 2;
grid-column-end: 14;
grid-row-start: 57;
grid-row-end: 59;
display: flex; /* Optional, for alignment purposes */
justify-content: left; /* Optional, center-align the image */
align-items: left; /* Optional, center-align vertically */
overflow: hidden; /* Prevent content overflow */
/* margin-bottom: 40px; */
flex-direction: column;
}

/* section 28 : problem 1 image  */
.page3_Section28_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 59;
  grid-row-end: 60;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 40px;
}

.page3_Section28_HeaderImage__img {
  max-width: 100%; /* Prevent the image from exceeding the container's width */
  height: auto; /* Maintain the aspect ratio */
}



/* Section 29 : Flow Chart text  */
.page3_Section29_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 61;
  grid-row-end: 63;
  display: flex; /* Optional, for alignment purposes */
  justify-content: left; /* Optional, center-align the image */
  align-items: left; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  /* margin-bottom: 40px; */
  flex-direction: column;
  }


/* section 30  : Flow Chart  image  */
.page3_Section30_HeaderImage {
  grid-column-start: 4;
  grid-column-end: 12;
  grid-row-start: 63;
  grid-row-end: 64;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 0px;
}

.page3_Section30_HeaderImage__img {
  max-width: 100%; /* Prevent the image from exceeding the container's width */
  height: auto; /* Maintain the aspect ratio */
}


/* Section 31 : wireframe Chart text  */
.page3_Section31_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 64;
  grid-row-end: 65;
  display: flex; /* Optional, for alignment purposes */
  justify-content: left; /* Optional, center-align the image */
  align-items: left; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  /* margin-bottom: 40px; */
  flex-direction: column;
  }


/* section 32  : wireframe  image 1 */
.page3_Section32_HeaderImage {
  grid-column-start: 3;
  grid-column-end: 13;
  grid-row-start: 65;
  grid-row-end: 66;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 30px;
}

.page3_Section32_HeaderImage__img {
  max-width: 100%; /* Prevent the image from exceeding the container's width */
  height: auto; /* Maintain the aspect ratio */
}

/* section 33 : Wireframe image 2  */
.page3_Section33_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 67;
  grid-row-end: 68;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 30px;
}

.page3_Section33_HeaderImage__img {
  max-width: 100%; /* Prevent the image from exceeding the container's width */
  height: auto; /* Maintain the aspect ratio */
}

/* section 34 : wireframe Image 3 */
.page3_Section34_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 68;
  grid-row-end: 69;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 30px;
}


/* section 35 : wireframe Image 4 */
.page3_Section35_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 70;
  grid-row-end: 71;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 30px;
}

/* section 36 : wireframe Image 5 */
.page3_Section36_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 72;
  grid-row-end: 73;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 30px;
}

/* section 37 : wireframe Image 6 */
.page3_Section37_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 74;
  grid-row-end: 75;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 30px;
}

/* section 38 : wireframe Image 7 */
.page3_Section38_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 76;
  grid-row-end: 77;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 30px;
}



  @media (max-width: 480px) {
    /* Add responsive styles here */

    .page3_container {
      margin-top: 60px;
      display: grid;
      row-gap: 10px;
      column-gap: 5px;
      grid-template-columns: 10px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 10px;
    }

        /* section 1: back arrow */
    .page3_backicon {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 2;
      grid-row-end: 3;
      display: flex;
      align-items: left;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      margin-top: 50px;
      margin-bottom: 10px;
    }

  /* Section 2: Title EasyWork and Subtitle */
  .page3_Header {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 4;
    display: flex;
    align-items: left;
    font-size: 34px;
    font-weight: 600;
    text-align: left;
    line-height: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .page3_Description {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 4;
    grid-row-end: 5;
    display: flex;
    align-items: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
    margin-bottom: 30px;
  }

  /* {section 3 : header image includes 3 wireframes } */
  .page3_HeaderImage {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 5;
    grid-row-end: 6;
    display: flex; /* Optional, for alignment purposes */
    justify-content: center; /* Optional, center-align the image */
    align-items: center; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
  }

/* 
  {/* Section 4: My role and Responsibility with time duration and tools  */
  .page3_info_bg {
    grid-column-start: 1;
    grid-column-end: 15;
    grid-row-start: 6;
    grid-row-end: 9;
    background: #eff1ff;
    /* height: 400px; */
    /* padding-bottom: 430px; */
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .page3_info_Title {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    line-height: 160%;
    margin-bottom: 4px;
  }
  
  .page3_info_Description {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    color: rgb(41, 41, 41);
  }
  
  .page3_section_left {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 6;
    grid-row-end: 7;
    margin-top: 75px;
    display: flex;
    justify-self: flex-start;
    flex-direction: column;
    text-align: left;
    margin-bottom: 0px;
  }
  
  .page3_section_right {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 7;
    grid-row-end: 8;
    margin-top: 0px;
    display: flex;
    justify-self: flex-start;
    flex-direction: column;
    text-align: left;
    margin-bottom: 35px;
  }
  
  .page3_TitleAndDescriptionGroup {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 30px;
  }


  /* section 5 : Design Challanes*/
  .page3_Header_Section5 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 9;
    grid-row-end: 10;
    display: flex;
    align-items: left;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    margin-top: 0px;
    margin-top: 30px;
    line-height: 150%;
    color: #3452E6;
  }
  
  .page3_Description_Section5 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 10;
    grid-row-end: 11;
    display: flex;
    align-items: left;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 10px;
    flex-direction: column;
    line-height: 150%;
    margin-top: 0px;
    margin-bottom: 15px;
  }
  
  .page3_BodyHeader {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    line-height: 150%;

    color: #000000;
    /* color: #3452E6; */
  }
  
  .page3_BodyText {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 160%;
    color: #474747;
  }

  .page3_BodyText_Highlight {
    font-weight: 600;
    color: black;
  }


  .page3_BodyText_Highlight_Blue {
    font-weight: 700;
    color: #3452E6;
  }

  /* section 6 : Image for Userpainpoints  */
  .page3_Section8_HeaderImage {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 11;
    grid-row-end: 12;
    display: flex; /* Optional, for alignment purposes */
    justify-content: center; /* Optional, center-align the image */
    align-items: center; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
    /* margin-bottom: 40px; */
  }
  
  .page3_Section8_HeaderImage__img {
    max-width: 100%; /* Prevent the image from exceeding the container's width */
    height: auto; /* Maintain the aspect ratio */
  }

  /* section 7 : Officer query   */
  .page3_SectionOfficerQuery_background{
    grid-column-start: 1;
    grid-column-end: 15;
    grid-row-start: 13;
    grid-row-end: 16;
    background: #eff1ff;
  }

  .page3_SectionOfficerQuery_Image{
    grid-column-start: 1;
    grid-column-end: 15;
    grid-row-start: 14;
    grid-row-end: 16;
   }

  /* section 8 : Design Process  */

  .page3_Section7_Text_Left{
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 17;
    grid-row-end: 18;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    line-height: 150%;
    color: #3452E6;
    margin-top: 40px;
  }

  .page3_Section7_Text_Right{
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 18;
    grid-row-end: 19;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    line-height: 160%;
    color: #1e1e1e;
    align-self: start;
  }


  .page3_Section7_Header{
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    line-height: 150%;
    color: #3452E6;
    margin-top: 0px;
  }

  .page3_Section7_HeaderImage {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 19;
    grid-row-end: 20;
    display: flex; /* Optional, for alignment purposes */
    justify-content: center; /* Optional, center-align the image */
    align-items: center; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
    margin-bottom: 40px;
  }
  
  .page3_Section7_HeaderImage__img {
    max-width: 100%; /* Prevent the image from exceeding the container's width */
    height: auto; /* Maintain the aspect ratio */
  }

  
  /* section 9 : Key Insights  */
  .page3_Section6_background{
    grid-column-start: 1;
    grid-column-end: 15;
    grid-row-start: 20;
    grid-row-end: 23;
    background: #eff1ff;
  }

  .page3_Section6_Text{
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 21;
    grid-row-end: 22;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 150%;
    padding-bottom: 0px;
    padding-top: 0px;
    color: #000000;
    margin-bottom: 30px;
  }

  .page3_Section6_Header{
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    line-height: 160%;
    color: #3452E6;
    margin-top: 40px;
  }


    /* section 10 : Phase 1 implementation highlights  */
  .page3_Header_Section10 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 12;
    grid-row-start: 23;
    grid-row-end: 24;
    display: flex;
    align-items: left;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    margin-top: 50px;
    line-height: 150%;
    color: #3452E6;
  }
  
  .page3_Description_Section10 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 24;
    grid-row-end: 25;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 150%;
    color: #1e1e1e;
    margin-top: 0px;
  }

  
    /* Section 11: dashboard image  */
    .page3_image1_Description{
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 150%;
    color: #3452E6;
    margin-top: 25px;
    }


  .page3_Section9_HeaderImage {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 25;
    grid-row-end: 26;
    display: flex; /* Optional, for alignment purposes */
    justify-content: center; /* Optional, center-align the image */
    align-items: center; /* Optional, center-align vertically */
    /* overflow: hidden; Prevent content overflow */
    margin-bottom: 10px;
    flex-direction: column;
  }
  
  .page3_Section9_HeaderImage__img {
    max-width: 100%; /* Prevent the image from exceeding the container's width */
    height: auto; /* Maintain the aspect ratio */
  }


  /* Section 12: Admin image  */
  .page3_image2_Description{
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 150%;
    color: #3452E6;
    margin-top: 25px;
    margin-bottom: 30px;
    }

  .page3_Section12_HeaderImage {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 27;
    grid-row-end: 28;
    display: flex; /* Optional, for alignment purposes */
    justify-content: left; /* Optional, center-align the image */
    align-items: left; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
    /* margin-bottom: 40px; */
    flex-direction: column;
  }
  
  .page3_Section12_HeaderImage__img {
    max-width: 100%; /* Prevent the image from exceeding the container's width */
    height: auto; /* Maintain the aspect ratio */
  }


  /* Section 13: dashboard image  */
  .page3_Section13_HeaderImage {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 29;
    grid-row-end: 30;
    display: flex; /* Optional, for alignment purposes */
    justify-content: center; /* Optional, center-align the image */
    align-items: center; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
    /* margin-bottom: 40px; */
    flex-direction: column;
  }
  
  .page3_Section13_HeaderImage__img {
    max-width: 100%; /* Prevent the image from exceeding the container's width */
    height: auto; /* Maintain the aspect ratio */
  }


  /* Section 13: rack image  */
        .page3_Section14_HeaderImage {
          grid-column-start: 2;
          grid-column-end: 14;
          grid-row-start: 30;
          grid-row-end: 31;
          display: flex; /* Optional, for alignment purposes */
          justify-content: center; /* Optional, center-align the image */
          align-items: center; /* Optional, center-align vertically */
          overflow: hidden; /* Prevent content overflow */
          margin-bottom: 0px;
          flex-direction: column;
        }
        
        .page3_Section14_HeaderImage__img {
          max-width: 100%; /* Prevent the image from exceeding the container's width */
          height: auto; /* Maintain the aspect ratio */
        }

    /* Section 15: rack image  */
    .page3_Section15_HeaderImage {
      grid-column-start: 2;
      grid-column-end: 14;
      grid-row-start: 32;
      grid-row-end: 33;
      display: flex; /* Optional, for alignment purposes */
      justify-content: center; /* Optional, center-align the image */
      align-items: center; /* Optional, center-align vertically */
      overflow: hidden; /* Prevent content overflow */
      margin-bottom: 0px;
      flex-direction: column;
    }
    
    .page3_Section15_HeaderImage__img {
      max-width: 100%; /* Prevent the image from exceeding the container's width */
      height: auto; /* Maintain the aspect ratio */
    }

      /* Section 16: rack image  */
      .page3_Section16_HeaderImage {
        grid-column-start: 2;
        grid-column-end: 14;
        grid-row-start: 34;
        grid-row-end: 35;
        display: flex; /* Optional, for alignment purposes */
        justify-content: center; /* Optional, center-align the image */
        align-items: center; /* Optional, center-align vertically */
        overflow: hidden; /* Prevent content overflow */
        margin-bottom: 0px;
        flex-direction: column;
      }
      
      .page3_Section16_HeaderImage__img {
        max-width: 100%; /* Prevent the image from exceeding the container's width */
        height: auto; /* Maintain the aspect ratio */
      }  


/* section 17 : answer from officer image  */
.page3_SectionOfficerAnswer_background{
  grid-column-start: 1;
  grid-column-end: 15;
  grid-row-start: 36;
  grid-row-end: 39;
  background: #eff1ff;  
}

.page3_SectionOfficerAnswer_Image{
  grid-column-start: 1;
  grid-column-end: 15;
  grid-row-start: 37;
  grid-row-end: 39;
 }

 /* section 18 : problems in phase one  */

 .page3_Header_Section18 {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 40;
  grid-row-end: 41;
  display: flex;
  align-items: left;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  margin-top: 40px;
  line-height: 150%;
  color: #3452E6;
}

.page3_Description_Section18 {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 41;
  grid-row-end: 43;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 150%;
  color: #1e1e1e;
  margin-top: 0px;
}

/* Section 19: Problem 1  */
.page3_Section19_HeaderImage {
grid-column-start: 2;
grid-column-end: 14;
grid-row-start: 43;
grid-row-end: 44;
display: flex; /* Optional, for alignment purposes */
justify-content: center; /* Optional, center-align the image */
align-items: center; /* Optional, center-align vertically */
overflow: hidden; /* Prevent content overflow */
/* margin-bottom: 40px; */
flex-direction: column;
}

/* section 20 : problem 1 image  */
.page3_Section20_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 45;
  grid-row-end: 46;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 0px;
}

.page3_Section20_HeaderImage__img {
  max-width: 100%; /* Prevent the image from exceeding the container's width */
  height: auto; /* Maintain the aspect ratio */
}

/* Section 21: Problem 2 text  */
.page3_Section21_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 47;
  grid-row-end: 48;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  /* margin-bottom: 40px; */
  flex-direction: column;
  }
  
  /* section 22 : problem 1 image  */
  .page3_Section22_HeaderImage {
    grid-column-start: 4;
    grid-column-end: 12;
    grid-row-start: 48;
    grid-row-end: 49;
    display: flex; /* Optional, for alignment purposes */
    justify-content: center; /* Optional, center-align the image */
    align-items: center; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
    margin-bottom: 0px;
  }
  
  .page3_Section22_HeaderImage__img {
    max-width: 100%; /* Prevent the image from exceeding the container's width */
    height: auto; /* Maintain the aspect ratio */
  }
  


/* Section 23: Problem 1 text  */
.page3_Section23_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 50;
  grid-row-end: 51;
  display: flex; /* Optional, for alignment purposes */
  justify-content: left; /* Optional, center-align the image */
  align-items: left; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  /* margin-bottom: 40px; */
  flex-direction: column;
  }
  
  /* section 24 : problem 1 image  */
  .page3_Section24_HeaderImage {
    grid-column-start: 4;
    grid-column-end: 12;
    grid-row-start: 51;
    grid-row-end: 52;
    display: flex; /* Optional, for alignment purposes */
    justify-content: center; /* Optional, center-align the image */
    align-items: center; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
    margin-bottom: 0px;
  }
  
  .page3_Section24_HeaderImage__img {
    max-width: 100%; /* Prevent the image from exceeding the container's width */
    height: auto; /* Maintain the aspect ratio */
  }
  
    
  /* section 25 : Key Insights  */
  .page3_Section25_background{
    grid-column-start: 1;
    grid-column-end: 15;
    grid-row-start: 53;
    grid-row-end: 56;
    background: #eff1ff;
  }

  .page3_Section25_Text{
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 54;
    grid-row-end: 55;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 150%;
    padding-bottom: 10px;

    padding-top: 15px;
    color: #000000;
    margin-bottom: 40px;
  }

  .page3_Section25_Header{
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: 160%;
    color: #3452E6;
    margin-top: 50px;
  }

  /* phase 2 start  */
 /* section 26 : phase 2 tag line   */

 .page3_Header_Section26 {
  grid-column-start: 2;
  grid-column-end: 10;
  grid-row-start: 56;
  grid-row-end: 57;
  display: flex;
  align-items: left;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  margin-top: 40px;
  line-height: 150%;
  color: #3452E6;
}

.page3_Description_Section26 {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 57;
  grid-row-end: 58;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  line-height: 150%;
  color: #1e1e1e;
  margin-top: 0px;
}

/* Section 27 : Problem 1  */
.page3_Section27_HeaderImage {
grid-column-start: 2;
grid-column-end: 14;
grid-row-start: 58;
grid-row-end: 59;
display: flex; /* Optional, for alignment purposes */
justify-content: left; /* Optional, center-align the image */
align-items: left; /* Optional, center-align vertically */
overflow: hidden; /* Prevent content overflow */
/* margin-bottom: 40px; */
flex-direction: column;
}

/* section 28 : problem 1 image  */
.page3_Section28_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 59;
  grid-row-end: 60;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 40px;
}

.page3_Section28_HeaderImage__img {
  max-width: 100%; /* Prevent the image from exceeding the container's width */
  height: auto; /* Maintain the aspect ratio */
}



/* Section 29 : Flow Chart text  */
.page3_Section29_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 61;
  grid-row-end: 62;
  display: flex; /* Optional, for alignment purposes */
  justify-content: left; /* Optional, center-align the image */
  align-items: left; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  /* margin-bottom: 40px; */
  flex-direction: column;
  }


/* section 30  : Flow Chart  image  */
.page3_Section30_HeaderImage {
  grid-column-start: 4;
  grid-column-end: 12;
  grid-row-start: 62;
  grid-row-end: 63;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 0px;
}

.page3_Section30_HeaderImage__img {
  max-width: 100%; /* Prevent the image from exceeding the container's width */
  height: auto; /* Maintain the aspect ratio */
}


/* Section 31 : Flow Chart text  */
.page3_Section31_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 63;
  grid-row-end: 64;
  display: flex; /* Optional, for alignment purposes */
  justify-content: left; /* Optional, center-align the image */
  align-items: left; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  /* margin-bottom: 40px; */
  flex-direction: column;
  }


/* section 32  : wireframe  image 1 */
.page3_Section32_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 64;
  grid-row-end: 65;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 0px;
}

.page3_Section32_HeaderImage__img {
  max-width: 100%; /* Prevent the image from exceeding the container's width */
  height: auto; /* Maintain the aspect ratio */
}

/* section 33 : Wireframe image 2  */
.page3_Section33_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 66;
  grid-row-end: 67;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 0px;
}

.page3_Section33_HeaderImage__img {
  max-width: 100%; /* Prevent the image from exceeding the container's width */
  height: auto; /* Maintain the aspect ratio */
}

/* section 34 : wireframe Image 3 */
.page3_Section34_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 68;
  grid-row-end: 69;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 0px;
}


/* section 35 : wireframe Image 4 */
.page3_Section35_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 70;
  grid-row-end: 71;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 0px;
}

/* section 36 : wireframe Image 5 */
.page3_Section36_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 72;
  grid-row-end: 73;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 0px;
}


/* section 37 : wireframe Image 6 */
.page3_Section37_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 74;
  grid-row-end: 75;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 40px;
}

/* section 38 : wireframe Image 7 */
.page3_Section38_HeaderImage {
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 76;
  grid-row-end: 77;
  display: flex; /* Optional, for alignment purposes */
  justify-content: center; /* Optional, center-align the image */
  align-items: center; /* Optional, center-align vertically */
  overflow: hidden; /* Prevent content overflow */
  margin-bottom: 40px;
}


    

    
  }
  