/* ===========================
   Main Parent Div
=========================== */
.parentDiv {
  display: flex;
  flex-direction: column; /* Arrange sections vertically */
}

/* ===========================
   Section 1: Intro
=========================== */
.section1-intro {
  background-color: #000; /* Black background */
  width: 100%;
  height: 82vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding-top: 70px;
}

.image-Hi {
  width: 260px;
  height: auto;
}

.text-intro {
  width: 50%;
}

.text-title-namaste {
  font-size: 16px;
  color: rgb(143, 143, 143); /* Grey */
  font-weight: 600;
  text-align: left;
}

.text-body-description {
  color: rgb(143, 143, 143); /* Grey */
  font-size: 23px;
  font-weight: 400;
  font-family: sans-serif;
  line-height: 1.6;
  text-align: left;
}

.white-word {
  color: #fff; /* White */
}

.button {
  border: 1px solid black;
  border-radius: 8px;
  padding: 10px 20px;
  background-color: transparent;
  color: black;
  cursor: pointer;
  white-space: nowrap;
  width: fit-content;
  margin-top: 25px;
}

.buttonDisabled {
  border: 1px solid gray;
  border-radius: 8px;
  padding: 10px 20px;
  background-color: transparent;
  cursor: pointer;
  white-space: nowrap;
  width: fit-content;
  margin-top: 25px;

  color: gray;
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.button:hover {
  background-color: black;
  color: white;
}

.button:active {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

/* ===========================
   (Scroll text)
=========================== */
.section2-intro {
  background-color: #000; /* Black background */
  width: 100%;
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #848484; /* Light grey text */
  padding-top: -10px;
}

.scrollDownText {
  font-size: 16px;
  color: #ffffff; /* White */
  display: flex;
  align-items: center;
}

.scroll-icon {
  margin-right: 8px; /* Space between icon and text */
  vertical-align: middle;
  font-size: 1.2rem;
  color: #848484; /* Light grey */
}


.container_Casestudy{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px; /* Adjust the gap as needed */
}
.caseStudyA {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 5%;
  margin-bottom: 100px;
}

.CaseStudyCard {
  display: grid;
  grid-template-columns: 10% 20% 20% 20% 20% 10%;
  grid-template-rows: 50px 50px 50px 110px 110px 110px;
}

.CaseSTudyAImage,
.CaseSTudyAImage-Pocus {
  width: 35%;
  height: 300px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow */
}

.CaseSTudyAImage-Pocus {
  background-color: #4087c5; /* Blue */
}

.CaseStudyTitle {
  font-size: 32px;
  font-weight: 600;
}

.CaseStudyTextArea {
  width: 28%;
  height: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
}

.CaseStudyDescription {
  font-size: 16px;
  font-weight: 300;
  color: rgb(11, 11, 11); /* Black */
  line-height: 1.5;
}

/* ===========================
Section 2
=========================== */
/* ===========================
   Case Studies
=========================== */

.Container_HomePageCard{
 margin-top: 20px;
 display: grid;
 row-gap: 50px;
 column-gap: 5px;
 grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr;
 background-color: rgb(255, 255, 255);
 margin-bottom: 100px;

}

.Container_HomePageCard_title{
 grid-column-start: 2;
 grid-column-end: 8;
 grid-row-start: 1;
 grid-row-end: 2;
 display: flex;
 align-items: left;
 font-size: 14px;
 padding-top: 50px;
}

.CardImage {
  width: 100%; /* Ensures the image takes up the full width of the container */
  height: 100%; /* Ensures the image takes up the full height of the container */
  object-fit: cover; /* Adjusts the image to cover the container without distortion */
  box-shadow: 0px 0px 20px hsla(0, 0%, 0%, 0.20); /* Subtle shadow on all sides */
  border-radius: 12px; /* Smooth, rounded corners */
  /* overflow: hidden; */
}


.Card1_Section_image{
 grid-column-start: 2;
 grid-column-end: 9;
 grid-row-start: 2;
 grid-row-end: 3;
 height: 350px;
 /* overflow: hidden; Prevents overflow */
}

.Card1_SectonInfo_Right{
 grid-column-start: 9;
 grid-column-end: 14;
 grid-row-start: 2;
 grid-row-end: 3;
 /* background-color: rgb(242, 218, 187); */
 height: 350px;
 display: flex;
 align-items: flex-start;
 padding-left: 30px;
 flex-direction: column;
 margin-bottom: 100px;
}

.CardTitle{
 padding-top: 20px;
 font-size: 38px;
 font-weight: 600;
 text-align:left;
}

.CardDescription{
 padding-top: 10px;
 font-size: 16px;
 font-weight: 400;
 line-height: 1.6;
 text-align:left;
 color: #000000;
}


/* Card 2  */
.Card1_Section_image_for2{
  grid-column-start: 7;
  grid-column-end: 14;
  grid-row-start: 4;
  grid-row-end: 5;
  height: 350px;
  /* overflow: hidden; Prevents overflow */
 }
 
 .Card1_SectonInfo_Right_for2{
  grid-column-start: 2;
  grid-column-end: 7;
  grid-row-start: 4;
  grid-row-end: 5;
  /* background-color: rgb(242, 218, 187); */
  height: 350px;
  display: flex;
  align-items: flex-start;
  padding-right: 30px;
  flex-direction: column;
  margin-bottom: 100px;
  /* height: 50px; */
 }
 
/* Card 3  */
.Card1_Section_image_for3{
  grid-column-start: 2;
  grid-column-end: 9;
  grid-row-start: 6;
  grid-row-end: 7;
  height: 350px;
  /* overflow: hidden; Prevents overflow */
 }
 
 .Card1_SectonInfo_Right_for3{
  grid-column-start: 9;
  grid-column-end: 14;
  grid-row-start: 6;
  grid-row-end: 7;
  /* background-color: rgb(242, 218, 187); */
  height: 350px;
  display: flex;
  align-items: flex-start;
  padding-left: 30px;
  flex-direction: column;
  margin-bottom: 100px;
  /* height: 50px; */
 }

 /* Card 4  */
.Card1_Section_image_for4{
  grid-column-start: 7;
  grid-column-end: 14;
  grid-row-start: 8;
  grid-row-end: 9;
  height: 350px;
  /* overflow: hidden; Prevents overflow */
 }
 
 .Card1_SectonInfo_Right_for4{
  grid-column-start: 2;
  grid-column-end: 7;
  grid-row-start: 8;
  grid-row-end: 9;
  /* background-color: rgb(242, 218, 187); */
  height: 350px;
  display: flex;
  align-items: flex-start;
  padding-right: 30px;
  flex-direction: column;
  margin-bottom: 100px;
  /* height: 50px; */
 }


 /* Card 5  */
.Card1_Section_image_for5{
  grid-column-start: 2;
  grid-column-end: 9;
  grid-row-start: 10;
  grid-row-end: 11;
  height: 350px;
  /* overflow: hidden; /*Prevents overflow */
 }
 
 .Card1_SectonInfo_Right_for5{
  grid-column-start: 9;
  grid-column-end: 14;
  grid-row-start: 10;
  grid-row-end: 11;
  /* background-color: rgb(242, 218, 187); */
  height: 350px;
  display: flex;
  align-items: flex-start;
  padding-left: 30px;
  flex-direction: column;
  margin-bottom: 100px;
  /* height: 50px; */
 }

  /* Card 6  */
.Card1_Section_image_for6{
  grid-column-start: 7;
  grid-column-end: 14;
  grid-row-start: 12;
  grid-row-end: 13;
  height: 350px;
  /* overflow: hidden; Prevents overflow */
 }
 
 .Card1_SectonInfo_Right_for6{
  grid-column-start: 2;
  grid-column-end: 7;
  grid-row-start: 12;
  grid-row-end: 13;
  /* background-color: rgb(242, 218, 187); */
  height: 350px;
  display: flex;
  align-items: flex-start;
  padding-right: 30px;
  flex-direction: column;
  margin-bottom: 100px;
  /* height: 50px; */
 }


/* ===========================
   Media Queries (Responsive)
=========================== */

/* Tablets (max-width: 768px) */
@media (max-width: 768px) {
  .section1-intro {
    flex-direction: column;
    height: 90vh;
  }

  .text-intro {
    width: 90%;
    text-align: center;
  }

  .text-body-description {
    font-size: 18px;
  }

  .image-Hi {
    width: 220px;
  }
}

/* Mobile Devices (max-width: 480px) */
@media (max-width: 480px) {
  .section1-intro {
    height: 80vh;
    padding-top: 15px;
  }

  .section2-intro {
    height: 8vh;
  } 

  .text-title-namaste {
    font-size: 14px;
  }

  .text-body-description {
    font-size: 16px;
    line-height: 1.4;
  }

  .image-Hi {
    width: 180px;
    margin-bottom: 20px;
  }
  
  .Container_HomePageCard{
    margin-top: 10px;
    display: grid;
    row-gap: 10px;
    column-gap: 5px;
    grid-template-columns: 10px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 10px;
    background-color: rgb(255, 255, 255);
    margin-bottom: 100px;
   
   }
   
   .Container_HomePageCard_title{
    grid-column-start: 2;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 2;
    display: flex;
    align-items: left;
    font-size: 14px;
    padding-top: 25px;
    margin-bottom: 25px;
   }
  
  
   
   .CardTitle{
    margin-top: 15px;
    padding-top: 0px;
    font-size: 20px;
    font-weight: 600;
   }
   
   .CardDescription{
    margin-top: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    text-align:left;
    color: #000000;
    margin-bottom: 5px;
    /* background-color: red; */
    
   }
   
   
.button {
  border: 1px solid black;
  border-radius: 8px;
  padding: 10px 18px;
  background-color: transparent;
  color: black;
  cursor: pointer;
  white-space: nowrap;
  width: fit-content;
  margin-top: 10px;
}

.buttonDisabled { /* For manually applying a 'disabled' class */
  
  border: 1px solid gray;
  border-radius: 8px;
  padding: 10px 18px;
  background-color: transparent;
  
  cursor: pointer;
  white-space: nowrap;
  width: fit-content;
  margin-top: 10px;
  color: gray;
  background-color: #f5f5f5;
  cursor: not-allowed;
}

/* card 1 */
.Card1_Section_image{
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 2;
  grid-row-end: 3;
  height: 220px;
  /* overflow: hidden; Prevents overflow */
 }

 .Card1_SectonInfo_Right{
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 4;
  grid-row-end: 5;
  /* background-color: rgb(242, 218, 187); */
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: 0px;
  flex-direction: column;
  margin-bottom: 80px;
 }

/* card 2 */
.Card1_Section_image_for2{
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 5;
  grid-row-end: 6;
  height: 220px;
  margin-bottom: 10px;
  /* overflow: hidden; Prevents overflow */
 }
 
 .Card1_SectonInfo_Right_for2{
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 6;
  grid-row-end: 7;
  /* background-color: rgb(242, 218, 187); */
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-right: 0px;
  flex-direction: column;
  margin-bottom: 80px;
  /* height: 50px; */
 }

 /* Card 3  */
.Card1_Section_image_for3{
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 8;
  grid-row-end: 9;
  height: 220px;
  /* overflow: hidden; Prevents overflow */
 }
 
 .Card1_SectonInfo_Right_for3{
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 10;
  grid-row-end: 11;
  /* background-color: rgb(242, 218, 187); */
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: 0px;
  flex-direction: column;
  margin-bottom: 80px;
  /* height: 50px; */
 }

 /* Card 4  */
.Card1_Section_image_for4{
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 12;
  grid-row-end: 13;
  height: 220px;
  /* overflow: hidden; Prevents overflow */
 }
 
 .Card1_SectonInfo_Right_for4{
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 14;
  grid-row-end: 15;
  /* background-color: rgb(242, 218, 187); */
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-right: 0px;
  flex-direction: column;
  margin-bottom: 80px;
  /* height: 50px; */
 }


 /* Card 5  */
.Card1_Section_image_for5{
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 16;
  grid-row-end: 17;
  height: 220px;
  /* overflow: hidden;  */
  margin-bottom: 30px;
 }
 
 .Card1_SectonInfo_Right_for5{
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 18;
  grid-row-end: 19;
  /* background-color: rgb(242, 218, 187); */
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: 0px;
  flex-direction: column;
  margin-bottom: 80px;
  /* overflow: hidden; */
  /* height: 50px; */
 }

  /* Card 6  */
.Card1_Section_image_for6{
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 20;
  grid-row-end: 21;
  height: 220px;
  /* overflow: hidden; Prevents overflow */
 }
 
 .Card1_SectonInfo_Right_for6{
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 22;
  grid-row-end: 23;
  /* background-color: rgb(242, 218, 187); */
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-right: 0px;
  flex-direction: column;
  margin-bottom: 80px;
  /* height: 50px; */
 }


}
