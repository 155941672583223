.CS2-bg-colour{
    background-color: rgb(255, 166, 118);
}

.CS2-text-colour{
    color: rgb(252, 118, 0);
}

.margin-cs2{
    margin-bottom: 100px;
} 