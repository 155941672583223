.CS_Pocus_container {
    margin-top: 50px;
    display: grid;
    row-gap: 30px;
    column-gap: 5px;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr;
    background-color: rgb(255, 255, 255);
  }
  

  /* section 1: back arrow */
.CS_Pocus_backicon {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
  display: flex;
  align-items: left;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 30px;
}

.CS_Pocus_backicon .back-arrow {
  display: inline-block;
  padding: 8px 12px; /* Add padding to make it more button-like */
  border-radius: 4px; /* Rounded corners for better aesthetics */
  cursor: pointer; /* Change the cursor to indicate a clickable button */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effect */
}

.CS_Pocus_backicon .back-arrow:hover {
  background-color: #3452e628; /* Highlight background color */
  color: #000; /* Change the arrow color for better contrast */
}

  /* Section 2: Title EASyWork and Subtitle */
  .CS_Pocus_Header {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 4;
    display: flex;
    align-items: left;
    font-size: 34px;
    font-weight: 600;
    text-align: left;
    line-height: 90%;
  }
  
  .CS_Pocus_Description {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 5;
    grid-column-end: 14;
    grid-row-start: 3;
    grid-row-end: 4;
    display: flex;
    align-items: left;
    font-size: 18px;
    font-weight: 400;
    line-height: 180%;
    text-align: left;
    padding-bottom: 50px;
  }
  
  /* {section 3 : header image includes 3 wireframes } */
  .CS_Pocus_HeaderImage {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 4;
    grid-row-end: 5;
    display: flex; /* Optional, for alignment purposes */
    justify-content: center; /* Optional, center-align the image */
    align-items: center; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
    /* max-height: 300px; */
  }
  
  .CS_Pocus_HeaderImage__img {
    max-width: 100%; /* Prevent the image from exceeding the container's width */
    height: auto; /* Maintain the aspect ratio */
  }


/* 
      {/* Section 4: My role and Responsibility with time duration and tools  */
  .CS_Pocus_info_bg {
    grid-column-start: 1;
    grid-column-end: 15;
    grid-row-start: 5;
    grid-row-end: 6;
    background: #eff1ff;
    /* height: 400px; */
    /* padding-bottom: 430px; */
    margin-top: 40px;
    margin-bottom: 10px;
  }
  
  .CS_Pocus_info_Title {
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    line-height: 160%;
    margin-bottom: 10px;
  }
  
  .CS_Pocus_info_Description {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    color: rgb(41, 41, 41);
  }
  
  .CS_Pocus_section_left {
    grid-column-start: 3;
    grid-column-end: 9;
    grid-row-start: 5;
    grid-row-end: 6;
    margin-top: 120px;
    display: flex;
    justify-self: flex-start;
    flex-direction: column;
    text-align: left;
    margin-bottom: 50px;
  }
  
  .CS_Pocus_section_right {
    grid-column-start: 10;
    grid-column-end: 14;
    grid-row-start: 5;
    grid-row-end: 6;
    margin-top: 120px;
    display: flex;
    justify-self: flex-start;
    flex-direction: column;
    text-align: left;
    margin-bottom: 50px;
  }
  
  .CS_Pocus_TitleAndDescriptionGroup {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 40px;
  }
  
  /* section 5 : Design Challanes*/
  .CS_Pocus_Header_Section5 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 6;
    grid-row-end: 7;
    display: flex;
    align-items: left;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    margin-top: 20px;
    margin-top: 50px;
    line-height: 150%;

    
    color: #3452E6;
  }
  
  .CS_Pocus_Description_Section5 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 5;
    grid-column-end: 14;
    grid-row-start: 6;
    grid-row-end: 7;
    display: flex;
    align-items: left;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 10px;
    flex-direction: column;
    line-height: 150%;
    margin-top: 50px;
    margin-bottom: 40px;
  }
  
  .CS_Pocus_BodyHeader {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    line-height: 150%;

    color: #000000;
    /* color: #3452E6; */
  }
  
  .CS_Pocus_BodyText {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    line-height: 160%;
    color: #474747;
  }

  .CS_Pocus_BodyText_Highlight {
    font-weight: 600;
    color: black;
  }


  .CS_Pocus_BodyText_Highlight_Blue {
    font-weight: 700;
    color: #3452E6;
  }

      /* section 8 : Design Process  */

      .CS_Pocus_Section7_Text_Left{
        grid-column-start: 2;
        grid-column-end: 5;
        grid-row-start: 8;
        grid-row-end: 9;
        font-size: 24px;
        font-weight: 600;
        text-align: left;
        line-height: 150%;
        color: #3452E6;
        margin-top: 18px;
      }

      .CS_Pocus_Section7_Text_Right{
        grid-column-start: 5;
        grid-column-end: 14;
        grid-row-start: 8;
        grid-row-end: 9;
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        line-height: 160%;
        color: #1e1e1e;
      }
    
    
      .CS_Pocus_Section7_Header{
        font-size: 24px;
        font-weight: 600;
        text-align: left;
        line-height: 150%;
        color: #3452E6;
        margin-top: 20px;
      }
  
      .CS_Pocus_Section7_HeaderImage {
        grid-column-start: 5;
        grid-column-end: 14;
        grid-row-start: 15;
        grid-row-end: 16;
        display: flex; /* Optional, for alignment purposes */
        justify-content: center; /* Optional, center-align the image */
        align-items: center; /* Optional, center-align vertically */
        overflow: hidden; /* Prevent content overflow */
        margin-bottom: 40px;
      }
      
      .CS_Pocus_Section7_HeaderImage__img {
        max-width: 100%; /* Prevent the image from exceeding the container's width */
        height: auto; /* Maintain the aspect ratio */
      }
  
  
  /* section 9 : Key Insights  */
  .CS_Pocus_Section6_background{
    grid-column-start: 1;
    grid-column-end: 15;
    grid-row-start: 16;
    grid-row-end: 19;
    background: #eff1ff;
  }

  .CS_Pocus_Section6_Text{
    grid-column-start: 4;
    grid-column-end: 12;
    grid-row-start: 17;
    grid-row-end: 18;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    line-height: 160%;
    padding-bottom: 20px;

    padding-top: 15px;
    color: #000000;
    margin-bottom: 40px;
  }

  .CS_Pocus_Section6_Header{
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: 160%;
    color: #3452E6;
    margin-top: 50px;
  }


    /* section 10 : Phase 1 implementation highlights  */
  .CS_Pocus_Header_Section10 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 10;
    grid-row-end: 11;
    display: flex;
    align-items: left;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    margin-top: 40px;
    line-height: 150%;
    color: #3452E6;
  }
  
  .CS_Pocus_Description_Section10 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 5;
    grid-column-end: 14;
    grid-row-start: 10;
    grid-row-end: 11;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    line-height: 150%;
    color: #1e1e1e;
    margin-top: 20px;
  }

   /* section 10.1 : Phase 1 implementation highlights  */
   .CS_Pocus_Header_Section10_2 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 12;
    grid-row-end: 13;
    display: flex;
    align-items: left;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    margin-top: 40px;
    line-height: 150%;
    color: #3452E6;
  }
  
  .CS_Pocus_Description_Section10_2 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 5;
    grid-column-end: 14;
    grid-row-start: 12;
    grid-row-end: 13;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    line-height: 150%;
    color: #1e1e1e;
    margin-top: 20px;
  }

  
    /* Section 11: dashboard image  */

    .CS_Pocus_image1_Description{
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 150%;
    color: #3452E6;
    margin-top: 25px;
    }


    .CS_Pocus_Section9_HeaderImage {
      grid-column-start: 2;
      grid-column-end: 14;
      grid-row-start: 11;
      grid-row-end: 12;
      display: flex; /* Optional, for alignment purposes */
      justify-content: center; /* Optional, center-align the image */
      align-items: center; /* Optional, center-align vertically */
      /* overflow: hidden; Prevent content overflow */
      margin-bottom: 10px;
      flex-direction: column;
    }
    
    .CS_Pocus_Section9_HeaderImage__img {
      max-width: 100%; /* Prevent the image from exceeding the container's width */
      height: auto; /* Maintain the aspect ratio */
    }

       /* section 10.1 : Phase 1 implementation highlights  */
   .CS_Pocus_Header_Section10_2{
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 13;
    grid-row-end: 14;
    display: flex;
    align-items: left;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    margin-top: 40px;
    line-height: 150%;
    color: #3452E6;
  }
  
  .CS_Pocus_Description_Section10_2 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 5;
    grid-column-end: 14;
    grid-row-start: 13;
    grid-row-end: 14;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    line-height: 150%;
    color: #1e1e1e;
    margin-top: 20px;
  }


        /* Section 12: dashboard image  */

    
        .CS_Pocus_image2_Description{
          font-size: 16px;
          font-weight: 500;
          text-align: left;
          line-height: 150%;
          color: #3452E6;
          margin-top: 25px;
          margin-bottom: 30px;
          }
      
        .CS_Pocus_Section12_HeaderImage {
          grid-column-start: 2;
          grid-column-end: 14;
          grid-row-start: 14;
          grid-row-end: 15;
          display: flex; /* Optional, for alignment purposes */
          justify-content: left; /* Optional, center-align the image */
          align-items: left; /* Optional, center-align vertically */
          overflow: hidden; /* Prevent content overflow */
          /* margin-bottom: 40px; */
          flex-direction: column;
        }
        
        .CS_Pocus_Section12_HeaderImage__img {
          max-width: 100%; /* Prevent the image from exceeding the container's width */
          height: auto; /* Maintain the aspect ratio */
        }


        /* Section 13: Hifi options  */

  
        .CS_Pocus_Section13_HeaderImage {
          grid-column-start: 2;
          grid-column-end: 14;
          grid-row-start: 15;
          grid-row-end: 16;
          display: flex; /* Optional, for alignment purposes */
          justify-content: left; /* Optional, center-align the image */
          align-items: left; /* Optional, center-align vertically */
          overflow: hidden; /* Prevent content overflow */
          /* margin-bottom: 40px; */
          flex-direction: column;
          margin-top: 50px;
        }
        
  

        /* Section 14: Hifi   */

  
        .CS_Pocus_Section14_HeaderImage {
          grid-column-start: 2;
          grid-column-end: 14;
          grid-row-start: 17;
          grid-row-end: 18;
          display: flex; /* Optional, for alignment purposes */
          justify-content: left; /* Optional, center-align the image */
          align-items: left; /* Optional, center-align vertically */
          overflow: hidden; /* Prevent content overflow */
          /* margin-bottom: 40px; */
          flex-direction: column;
          margin-top: 50px;
        }
    
  @media (max-width: 480px) {
    /* Add responsive styles here */

    .CS_Pocus_container {
      margin-top: 60px;
      display: grid;
      row-gap: 10px;
      column-gap: 5px;
      grid-template-columns: 10px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 10px;
    }

        /* section 1: back arrow */
    .CS_Pocus_backicon {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: 2;
      grid-row-end: 3;
      display: flex;
      align-items: left;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      margin-top: 50px;
      margin-bottom: 10px;
    }

  /* Section 2: Title EasyWork and Subtitle */
  .CS_Pocus_Header {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 4;
    display: flex;
    align-items: left;
    font-size: 34px;
    font-weight: 600;
    text-align: left;
    line-height: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .CS_Pocus_Description {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 4;
    grid-row-end: 5;
    display: flex;
    align-items: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
    margin-bottom: 30px;
    padding-bottom: 0px;
  }

  /* {section 3 : header image includes 3 wireframes } */
  .CS_Pocus_HeaderImage {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 5;
    grid-row-end: 6;
    display: flex; /* Optional, for alignment purposes */
    justify-content: center; /* Optional, center-align the image */
    align-items: center; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
  }

/* 
  {/* Section 4: My role and Responsibility with time duration and tools  */
  .CS_Pocus_info_bg {
    grid-column-start: 1;
    grid-column-end: 15;
    grid-row-start: 6;
    grid-row-end: 9;
    background: #eff1ff;
    /* height: 400px; */
    /* padding-bottom: 430px; */
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .CS_Pocus_info_Title {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    line-height: 160%;
    margin-bottom: 4px;
  }
  
  .CS_Pocus_info_Description {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    color: rgb(41, 41, 41);
  }
  
  .CS_Pocus_section_left {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 6;
    grid-row-end: 7;
    margin-top: 75px;
    display: flex;
    justify-self: flex-start;
    flex-direction: column;
    text-align: left;
    margin-bottom: 0px;
  }
  
  .CS_Pocus_section_right {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 7;
    grid-row-end: 8;
    margin-top: 0px;
    display: flex;
    justify-self: flex-start;
    flex-direction: column;
    text-align: left;
    margin-bottom: 35px;
  }
  
  .CS_Pocus_TitleAndDescriptionGroup {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 30px;
  }


  /* section 5 : Design Challanes*/
  .CS_Pocus_Header_Section5 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 9;
    grid-row-end: 10;
    display: flex;
    align-items: left;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    margin-top: 0px;
    margin-top: 30px;
    line-height: 150%;
    color: #3452E6;
  }
  
  .CS_Pocus_Description_Section5 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 10;
    grid-row-end: 11;
    display: flex;
    align-items: left;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 10px;
    flex-direction: column;
    line-height: 150%;
    margin-top: 0px;
    margin-bottom: 15px;
  }
  
  .CS_Pocus_BodyHeader {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    line-height: 150%;

    color: #000000;
    /* color: #3452E6; */
  }
  
  .CS_Pocus_BodyText {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 160%;
    color: #474747;
  }

  .CS_Pocus_BodyText_Highlight {
    font-weight: 600;
    color: black;
  }

  .CS_Pocus_BodyText_Highlight_Blue {
    font-weight: 700;
    color: #3452E6;
  }

  /* section 8 : Design Process  */

  .CS_Pocus_Section7_Text_Left{
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 12;
    grid-row-end: 13;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    line-height: 150%;
    color: #3452E6;
    margin-top: 40px;
  }

  .CS_Pocus_Section7_Text_Right{
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 13;
    grid-row-end: 14;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    line-height: 160%;
    color: #1e1e1e;
    align-self: start;
  }


  .CS_Pocus_Section7_Header{
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    line-height: 150%;
    color: #3452E6;
    margin-top: 0px;
  }

  .CS_Pocus_Section7_HeaderImage {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 19;
    grid-row-end: 20;
    display: flex; /* Optional, for alignment purposes */
    justify-content: center; /* Optional, center-align the image */
    align-items: center; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
    margin-bottom: 40px;
  }
  
  .CS_Pocus_Section7_HeaderImage__img {
    max-width: 100%; /* Prevent the image from exceeding the container's width */
    height: auto; /* Maintain the aspect ratio */
  }

  
  /* section 9 : Key Insights  */
  .CS_Pocus_Section6_background{
    grid-column-start: 1;
    grid-column-end: 15;
    grid-row-start: 20;
    grid-row-end: 23;
    background: #eff1ff;
  }

  .CS_Pocus_Section6_Text{
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 21;
    grid-row-end: 22;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 150%;
    padding-bottom: 0px;
    padding-top: 0px;
    color: #000000;
    margin-bottom: 30px;
  }

  .CS_Pocus_Section6_Header{
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    line-height: 160%;
    color: #3452E6;
    margin-top: 40px;
  }


    /* section 10 : Phase 1 implementation highlights  */
  .CS_Pocus_Header_Section10 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 12;
    grid-row-start: 22;
    grid-row-end: 23;
    display: flex;
    align-items: left;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    margin-top: 0px;
    line-height: 150%;
    color: #3452E6;
  }
  
  .CS_Pocus_Description_Section10 {
    /* background-color: rgb(190, 190, 236); */
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 23;
    grid-row-end: 24;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 150%;
    color: #1e1e1e;
    margin-top: 0px;
  }

    /* section 10 : Phase 1 implementation highlights  */
    .CS_Pocus_Header_Section10_2 {
      /* background-color: rgb(190, 190, 236); */
      grid-column-start: 2;
      grid-column-end: 12;
      grid-row-start: 25;
      grid-row-end: 26;
      display: flex;
      align-items: left;
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      margin-top: 50px;
      line-height: 150%;
      color: #3452E6;
    }
    
    .CS_Pocus_Description_Section10_2 {
      /* background-color: rgb(190, 190, 236); */
      grid-column-start: 2;
      grid-column-end: 14;
      grid-row-start: 26;
      grid-row-end: 27;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      line-height: 150%;
      color: #1e1e1e;
      margin-top: 0px;
    }

  
    /* Section 11: dashboard image  */
    .CS_Pocus_image1_Description{
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 150%;
    color: #3452E6;
    margin-top: 25px;
    }


  .CS_Pocus_Section9_HeaderImage {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 24;
    grid-row-end: 25;
    display: flex; /* Optional, for alignment purposes */
    justify-content: center; /* Optional, center-align the image */
    align-items: center; /* Optional, center-align vertically */
    /* overflow: hidden; Prevent content overflow */
    margin-bottom: 10px;
    flex-direction: column;
  }
  
  .CS_Pocus_Section9_HeaderImage__img {
    max-width: 100%; /* Prevent the image from exceeding the container's width */
    height: auto; /* Maintain the aspect ratio */
  }


  /* Section 12: Admin image  */
  .CS_Pocus_image2_Description{
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 150%;
    color: #3452E6;
    margin-top: 25px;
    margin-bottom: 30px;
    }

  .CS_Pocus_Section12_HeaderImage {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 27;
    grid-row-end: 28;
    display: flex; /* Optional, for alignment purposes */
    justify-content: left; /* Optional, center-align the image */
    align-items: left; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
    /* margin-bottom: 40px; */
    flex-direction: column;
  }
  
  .CS_Pocus_Section12_HeaderImage__img {
    max-width: 100%; /* Prevent the image from exceeding the container's width */
    height: auto; /* Maintain the aspect ratio */
  }


  /* Section 13: Hifi options  */


  .CS_Pocus_Section13_HeaderImage {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 29;
    grid-row-end: 30;
    display: flex; /* Optional, for alignment purposes */
    justify-content: left; /* Optional, center-align the image */
    align-items: left; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
    /* margin-bottom: 40px; */
    flex-direction: column;
    
    margin-top: 10px;
  }

    

  /* Section 13: Hifi options  */


  .CS_Pocus_Section14_HeaderImage {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 31;
    grid-row-end: 32;
    display: flex; /* Optional, for alignment purposes */
    justify-content: left; /* Optional, center-align the image */
    align-items: left; /* Optional, center-align vertically */
    overflow: hidden; /* Prevent content overflow */
    /* margin-bottom: 40px; */
    flex-direction: column;
    
    margin-top: 10px;
  }


    
  }
  